.app__certificate {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }


  
  .app__certificate {
    width: 80%;
    flex-wrap: wrap;
    margin-top: 2rem;
  
    div {
      width: 400px;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        filter: grayscale(1);
      }
  
      &:hover {
        img {
          filter: grayscale(0);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 300px;
        
      }
  
      @media screen and (max-width: 450px) {
        width: 210px;
    
      }
    }
  
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }



  .app__certificate-btns {
    flex-direction: row;
    margin-top: 1rem;
  
    div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: var(--white-color);
  
      margin: 1rem;
      transition: all 0.3s ease-in-out;
  
      svg {
        width: 20px;
        height: 20px;
        color: var(--secondary-color);
      }
  
      &:hover {
        background-color: var(--secondary-color);
  
        svg {
          color: var(--white-color);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 100px;
        height: 100px;
  
        svg {
          width: 45px;
          height: 45px;
        }
      }
    }
  }
  
  